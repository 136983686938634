import React, { useRef, useEffect } from "react"
import { TimelineMax, Power4 } from "gsap"
import scrollToElement from "scroll-to-element"

import CallToAction from "../components/CallToAction"
export default function DesktopHeadline({
  mobileText,
  headerText,
  headerTextClass,
  desktopText,
  scrollTo,
  offset,
}) {
  const header = useRef(null)
  const cta = useRef(null)
  const headerTxt = useRef(null)

  useEffect(() => {
    let tl = new TimelineMax({
      onComplete: () => {
        if (header.current) {
          let newHeader = ""
          let all = document.querySelectorAll(".cb-header-animation-container")
          all.forEach((item, i) => {
            newHeader += item.firstChild.innerHTML + "</br> "
          })

          header.current.firstChild.innerHTML = newHeader
        }
      },
    })
    let headerChildren = [...header.current.firstChild.children].map(child => {
      return child.firstElementChild
    })

    tl.to([header.current, cta.current], 0, {
      css: { visibility: "visible" },
    })
    tl.staggerFrom(
      headerChildren,
      1.1,
      { y: "100%", ease: Power4.easeOut },
      0.185,
      0.3
    )

    if (headerTxt.current) {
      tl.to(
        [headerTxt.current],
        0,
        {
          css: { visibility: "visible" },
        },
        0
      )

      tl.staggerFromTo(
        [headerTxt.current, cta.current],
        0.8,
        { opacity: 0, y: "30" },
        { opacity: 1, y: "0", ease: "Power2.easeOut" },
        0.25,
        "-=.75"
      )
    } else {
      tl.fromTo(
        cta.current,
        0.8,
        { opacity: 0, y: "30" },
        { opacity: 1, y: "0", ease: "Power2.easeOut" },
        "-=.7"
      )
    }
  }, [])

  return (
    <>
      <div className="cb-header mb" ref={header}>
        <h1>
          {desktopText.map(item => {
            return (
              <div key={item} className="cb-header-animation-container">
                <span>{item}</span>
              </div>
            )
          })}
        </h1>
      </div>

      {headerText && (
        <div ref={headerTxt} className={headerTextClass}>
          <p>{headerText}</p>
        </div>
      )}

      {scrollTo && <CallToAction ref={cta} scrollTo={scrollTo} offset={offset}>
        Erkunden
      </CallToAction>}
    </>
  )
}
