import React from "react"


import MobileHeadline from "../components/MobileHeadline"
import DesktopHeadline from "../components/DesktopHeadline"
export default function AnimatedHeadline(props) {
  let mobile = false;
  let desktop = false;
  if(typeof window !== "undefined" && window.innerWidth < 768){
    mobile = true
  }
  if(typeof window !== "undefined" && window.innerWidth >= 768){
    desktop = true
  }

  return (
    <div>
      {mobile && <MobileHeadline {...props} />}
      {desktop && <DesktopHeadline {...props} />}
    </div>

  )
}
