import React from 'react'
import styles from "../styles/_about.module.scss"
import Link from "gatsby-link"
export default function Kontakt() {
    return (
      <section className={` ${styles.cbRequest}`}>
        <div className={` ${styles.cbRequestWrap}`} data-aos="fade-up">
          <div className={` ${styles.cbRequestLink} cb-header`}>
            <Link to="/kontakt">
              <h1>
                <span>Kontakt</span> aufnehmen
              </h1>
            </Link>
          </div>
        </div>
      </section>
    )
}
