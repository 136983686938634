import React, { useRef, useEffect } from "react"
import { TimelineLite } from "gsap"

import CallToAction from "../components/CallToAction"
export default function MobileHeadline({
  mobileText,
  headerText,
  headerTextClass,
  desktopText,
  scrollTo,
  offset,
}) {
  const header = useRef(null)
  const cta = useRef(null)
  const headerTxt = useRef(null)
  useEffect(() => {
    splitLines(header.current.firstChild, "<span>", "</span>")
  }, [])

  // Openingtag & closingtag has to be a string!!
  function splitLines(container, openingtag, closingtag) {
    let lines = 0
    // Get the spans in the paragraph
    var spans = container.children,
      top = 0,
      // set tmp as a string
      tmp = ""

    let beforeDiv = "<div class='cb-header-animation-container'>"
    let afterDiv = "</div>"
    // Put spans on each word, for now we use the <n> tag because, we want to save 5 bytes:)
    container.innerHTML = container.textContent.replace(/\S+/g, "<n>$&</n>")

    // Loop through each words (spans)
    for (let i = 0; i < spans.length; i++) {
      // Get the height of each word
      var rect = spans[i].getBoundingClientRect().top

      // If top is different as the last height of the word use a closingtag and use an opentag after that
      /*  console.log(rect); */

      if (top < rect) {
        lines++
        tmp += closingtag + afterDiv + beforeDiv + openingtag
      }
      top = rect

      // Add the spans + space between each word
      tmp += spans[i].textContent + " "
    }

    // Add the lines back to the paragraph
    container.innerHTML = tmp += closingtag

    let tl = new TimelineLite({
      onComplete: () => {
        if (header.current) {
          let newHeader = ""
          let all = document.querySelectorAll(".cb-header-animation-container")
          all.forEach((item, i) => {
            newHeader += item.firstChild.innerHTML + "</br> "
          })

          header.current.firstChild.innerHTML = newHeader
        }
      },
    })

    let headerChildren = [...header.current.firstChild.children].map(child => {
      return child.firstElementChild
    })

    tl.to([header.current, cta.current], 0, {
      css: { visibility: "visible" },
    })
    tl.staggerFrom(
      headerChildren,
      1.1,
      { y: "100%", ease: "Power4.easeOut" },
      0.185,
      0.3
    )

    if (headerTxt.current) {
      tl.to(
        [headerTxt.current],
        0,
        {
          css: { visibility: "visible" },
        },
        0
      )

      tl.staggerFromTo(
        [headerTxt.current, cta.current],
        0.8,
        { opacity: 0, y: "15" },
        { opacity: 1, y: "0", ease: "Power2.easeOut" },
        0.25,
        "-=.85"
      )
    } else {
      tl.fromTo(
        cta.current,
        0.8,
        { opacity: 0, y: "15" },
        { opacity: 1, y: "0", ease: "Power2.easeOut" },
        "-=.85"
      )
    }
  }

  return (
    <>
      <div className="cb-header mb" ref={header}>
        <h1>{mobileText}</h1>
      </div>

      {headerText && (
        <div ref={headerTxt} className={headerTextClass}>
          <p>{headerText}</p>
        </div>
      )}

      {scrollTo && (
        <CallToAction ref={cta} scrollTo={scrollTo} offset={offset}>
          Erkunden
        </CallToAction>
      )}
    </>
  )
}
